<template>
  
  <div class="footer">
    
    <div class="footer_class">
      <p class="big_titles">能力矩阵</p>
      <div class="footer_main">
        <div class="foot_p">
          <p>NLP文本处理</p>
          <span
            >依托大模型能⼒，提供多语翻译、自动⽣成商品详情、关键信息提取、文章生成、情感分析、⽣成式问答系统等功能及⽂本相似解决⽅案。</span
          >
        </div>

        <div class="foot_p">
          <p>CV图像视频处理</p>
          <span
            >全⾯的图像理解和处理能力，包括品牌⾊情识别等商品安全场景，抠图、水印涂抹、视频⽣成等能力，具有图像搜索功能，适用于拍照搜同款、相似商品推荐等场景。</span
          >
        </div>

        <div class="foot_p">
          <p>ML数据挖掘及增长</p>
          <span
            >敏锐精准的⼤数据分析挖掘，应⽤于智能优惠券、流失预警和增益分析等站内营销场景，助力转化升级。</span
          >
        </div>

        <div class="foot_p">
          <p>多模态图文理解</p>
          <span
            >打造跨模态领域模型，拥有图文交互能力，推进⽣成式AI解决⽅案落地应⽤。</span
          >
        </div>
      </div>
      <p class="food_tit">
        Copyright Notice © 2004 - 2023 DHgate.com All rights reserved. License
        <a href="https://beian.miit.gov.cn/">京ICP备18054285号-3</a>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: "viewfooter",
  props: [],
  data() {
    return {};
  },

  mounted() {},
};
</script>

<style scoped>
.footer {
  width: 100%;
  height: 560px;
  background: #f9f7fb;
  opacity: 1;
}

.footer_class {
  display: flex;
  flex-direction: column;
  width: 1200px;
  margin: 0 auto;
  align-items: center;
}

.footer_main {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
}

.foot_p {
  display: flex;
  flex-direction: column;
  align-items: center;
}



.footer_title {
  font-size: 32px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #333333;
  line-height: 32px;
  margin: 99px 0 60px;
}

.foot_p p {
  font-size: 22px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #333333;
  line-height: 0px;
  margin: 0 0 48px;
  padding: 0;
}

.foot_p span {
  width: 236px;
  font-size: 16px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #656565;
  line-height: 26px;
}

.food_tit {
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #656565;
  line-height: 18px;
  margin-top: 100px;
}
.food_tit a {
  color: #656565;
}
@media (max-width: 990px)  {
  .footer{
    height: auto;
    background: #fff;
  }
  .footer_class{
    width: 100%;
  }
  .footer_main{
    display: block;
  }
  .foot_p{
    display: block;
    margin: 0 auto;
    margin-bottom: 50px;
    text-align: center;
    width: 85%;
    padding: 30px 20px;
    border-radius: 12px;
    /* box-shadow: ; */
    background: #f9f7fb;
    
  }
  .food_tit{
    text-align: center;
    font-size: 12px;
    width: calc(100% - 40px);
    margin: 50px auto;
  }
  .footer_title{
    font-size: 22px;
    margin: 40px 0 40px;
  }
  .foot_p p{
    font-size: 18px;
    margin: 0 0 20px;
  }
  .foot_p span{
    font-size: 14px;
    width: 250px;
 
  }
}
</style>
