<template>
    <div>
        <div class="banner_head">
            <div class="bannerTitle">
                <span class="titleBig">{{bigTitle_1}}</span>
                <span class="titleSmall">{{smallTitle_1}}</span>
            </div>
            <img :src="bannerImg" class="leftImg">
        </div>
      
    </div>
</template>

<script>
export default {
    name: "bannerHead",
    props: ["bannerImg","bigTitle_1","smallTitle_1"],
    data() {
        return {};
    },

    mounted() {},
};
</script>

<style scoped>
.banner_head {
    background: url(../../assets/img/children/banner.png) no-repeat center;
    width: 100%;
    height: 450px;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
}

.leftImg{
    width: 555px;
    height: 348px;
    border-radius: 10px;
}

.bannerTitle {
    width: 530px;
    /* background-color: #000000; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 75px;
    margin-top: -29px;
}

.titleBig {
    font-size: 48px;
    font-family: PingFang-SC-Bold;
    font-weight: 800;
    color: #000000;
    line-height: 48px;
    margin-bottom: 34px;
}

.titleSmall {
    width: 430px;
    height: 45px;
    font-size: 18px;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: #000000;
    line-height: 28px;
    text-align: center;
}


@media (max-width: 990px)  {
   

.banner_head {
    background: url(../../assets/img/children/banner.png) no-repeat center;
    width: 100%;
    height: 240px;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
}

.leftImg{
    width: 200px;
    height: 130px;
    border-radius: 10px;
    margin-top: -20px;
}

.bannerTitle {
    width: 200px;
    /* background-color: #000000; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 25px;
    margin-top: -40px;
}

.titleBig {
    font-size:20px;
    font-family: PingFang-SC-Bold;
    font-weight: 800;
    color: #000000;
    line-height: 30px;
    margin-bottom: 0px;
}

.titleSmall {
    width: 200px;
    /* height: 45px; */
    font-size: 12px;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: #000000;
    line-height: 25px;
    text-align: center;
}
}
</style>
