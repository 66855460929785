<!--
 * @Date: 2024-01-08 15:58:49
 * @LastEditTime: 2024-02-20 11:18:21
 * @Description: 导航tag
-->
<template>
  <div class="item-tag" :class="['tag-' + type]">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'ItemTag',
  props: {
    text: {
      type: String,
      required: true,
    },
    // 1即将更新，2近期开放
    type: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="less" scoped>
.item-tag {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 10px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;
  padding: 2px 6px;
  transform: translateX(60%);
  &::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px 0 3px 0;
    transform: skew(-15deg);
  }
  &.tag-1 {
    color: #f53f3f;
    &::before {
      background: rgba(245, 63, 63, 0.1);
    }
  }
  &.tag-2 {
    color: #6c38e0;

    transform: translateX(105%);
    &::before {
      background: rgba(108, 56, 224, 0.1);
    }
  }
}
</style>
