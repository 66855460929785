<!--
 * @Description: code管理侧边栏
 * @Author: LZ
 * @Date: 2023-10-23 10:12:50
-->
<template>
  <el-row class="tac">
    <el-col :span="12">
      <el-menu
        default-active="1"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        background-color="#f2f3f5"
        text-color="#1f2329"
        active-text-color="#250DBB"
      >
        <el-menu-item index="1" @click="jump()">
          <i class="el-icon-files"></i>
          <span slot="title">code管理</span>
        </el-menu-item>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>
import "../../assets/css/public.css";
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    jump() {
      this.$router.push({
        path: "/listCode",
      });
    },
  },
};
</script>
