<!--
 * @Date: 2024-01-05 17:55:45
 * @LastEditTime: 2024-03-20 18:28:18
 * @Description: 子集菜单
-->
<template>
  <div v-if="data.status != 2" class="menu-wrap">
    <div
      @mouseenter.stop="handleHover(data)"
      @mouseleave.stop="handleBlur"
      @click.stop="handleJump(data)"
      class="title"
      :class="{
        disable: data.status == 0 || data.tips == 2,
        'event-none': !data.url || data.status != 1,
      }"
    >
      <template v-if="data.type == 1">
        <img
          v-if="data.status == 0 || data.tips == 2"
          class="saas-type"
          type="disabled"
          src="@/assets/img/nav/type-gray.png"
          alt=""
        />
        <template v-else>
          <img
            class="saas-type"
            type="normal"
            src="@/assets/img/nav/type-normal.png"
            alt=""
          />
          <img
            class="saas-type"
            type="hover"
            src="@/assets/img/nav/type-hover.png"
            alt=""
          />
        </template>
      </template>
      <p>{{ data.name }}</p>
      <template v-if="data.url || data.status != 1">
        <img
          class="arrow"
          v-if="data.status == 0 || data.tips == 2"
          src="@/assets/img/nav/triangle-gray.png"
        />
        <template v-else>
          <img
            class="arrow"
            type="normal"
            src="@/assets/img/nav/triangle-normal.png"
          />
          <img
            class="arrow"
            type="hover"
            src="@/assets/img/nav/arrow-hover.png"
          />
        </template>
      </template>
      <ItemTag
        v-if="data.tips"
        :text="data.tips == 1 ? '即将更新' : '近期开放'"
        :type="data.tips"
      />
    </div>
    <div class="underline"></div>
    <ul
      class="children-list"
      :class="{ 'more-padding': data.children.some((item) => item.type == 1) }"
      v-if="data.children && data.children.length"
    >
      <li
        v-for="item in data.children"
        @mouseenter.stop="handleHover(item)"
        @mouseleave.stop="handleBlur"
        @click.stop="handleJump(item)"
        :class="{
          disable: item.status == 0 || item.tips == 2,
          'event-none': !item.url || data.status != 1,
        }"
      >
        <template v-if="item.type == 1">
          <div class="sass-icon">
            <img
              v-if="item.status == 0 || item.tips == 2"
              class="saas-type"
              type="disabled"
              src="@/assets/img/nav/type-gray.png"
              alt=""
            />
            <template v-else>
              <img
                class="saas-type"
                type="normal"
                src="@/assets/img/nav/type-normal.png"
                alt=""
              />
              <img
                class="saas-type"
                type="hover"
                src="@/assets/img/nav/type-hover.png"
                alt=""
              />
            </template>
          </div>
        </template>
        <p>{{ item.name }}</p>
        <img
          class="arrow"
          v-if="item.status == 0 || item.tips == 2"
          src="@/assets/img/nav/triangle-gray.png"
        />
        <template v-else>
          <img
            class="arrow"
            type="normal"
            v-if="item.status == 1"
            src="@/assets/img/nav/triangle-normal.png"
          />
          <img
            class="arrow"
            type="hover"
            v-if="item.status == 1"
            src="@/assets/img/nav/arrow-hover.png"
          />
        </template>
        <ItemTag
          v-if="item.tips"
          :text="item.tips == 1 ? '即将更新' : '近期开放'"
          :type="item.tips"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import ItemTag from './tag.vue';
import '@/assets/css/normalize.css';
export default {
  name: 'ThirdMenu',
  components: { ItemTag },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    /**
     * @description: 菜单hover
     * @param {*} item
     * @return {*}
     */
    handleHover(item) {
      this.$emit('hover', item);
    },

    /**
     * @description: 菜单blur
     * @return {*}
     */
    handleBlur() {
      this.$emit('blur');
    },

    /**
     * @description: 跳转页面
     * @param {*} item
     * @return {*}
     */
    handleJump(item) {
      this.$emit('jump', item);
    },
  },
};
</script>

<style lang="less" scoped>
.disable {
  color: #c9cdd4 !important;
  pointer-events: none;
}
.event-none {
  pointer-events: none;
}
.menu-wrap {
  position: relative;
  min-width: 194px;
}
.title {
  width: fit-content;
  height: 40px;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #000108;
  margin-bottom: 6px;
  cursor: pointer;
}
.underline {
  // position: absolute;
  width: 100%;
  height: 1px;
  bottom: -7px;
  left: 0;
  background: #f2f3f5;
}
.children-list {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 14px 0 0 0;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;
  color: #4e5969;
  li {
    cursor: pointer;
    padding: 6px 0 6px 24px;
    width: fit-content;
  }
}
.children-list li,
.title {
  position: relative;
  display: flex;
  align-items: center;
  &:hover {
    color: #6c38e0;
  }
  &:hover .arrow[type='normal'] {
    opacity: 0;
  }
  &:hover .arrow[type='hover'] {
    opacity: 1;
    display: block;
    position: relative;

    transition-delay: 0s;
  }
  img.arrow {
    height: 20px;
    margin-left: 4px;
    transition: all 0.2s;
    &[type='hover'] {
      transform: translateX(-26px);
      opacity: 0;
    }
  }
  p {
    word-break: keep-all;
    white-space: pre;
  }

  .saas-type {
    display: none;
    width: 20px;
    margin-right: 8px;
    &[type='normal'] {
      display: block;
    }
  }
  &:hover .saas-type[type='normal'] {
    display: none;
  }
  &:hover .saas-type[type='hover'] {
    display: block;
  }
}
.sass-icon {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}
.more-padding {
  li {
    padding-left: 36px;
  }
}
</style>
