<template>
  <div>
    <div class="differentiationAdvantage">
        <div class="bady_width">
          <p class="big_titles">差异优势</p>
          <div class="diff_content">
            <div class="diff_span">
              <div v-for="(item, index) in diffList" :key="index" class="diff_items">
                <span class="diff_item1">{{ item.diffFirst }}</span>
                <span class="diff_item2">{{ item.diffSecond }}</span>
              </div>
            </div>
            <div class="diffImg">
              <img :src="diffImg"/>
            </div>
          </div>
        </div>
    </div>
   
  </div>
</template>

<script>
// 公用组件
import "../../assets/css/public.css";
export default {
  name: "differentialAdv",
  props: ["diffImg", "diffList"],
  data() {
    return {};
  },

  mounted() {},
};
</script>

<style scoped>
.diffImg {
  width: 550px;
  height: 430px;
  border-radius: 18px;
  overflow: hidden;
  /* background: #eeebf3; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.diffImg img{
  width: 100%;
  border-radius: 10px;
}
.diff_content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.diff_span {
  width: 650px;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-right: 51px;
}

.diff_items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 64px;
}

.diff_items:last-child{
    margin-bottom: 0;
}

.diff_item1 {
  font-size: 22px;
  font-family: PingFang-SC-Bold;
  font-weight: 800;
  color: #333333;
  line-height: 22px;
  margin-bottom: 19px;
}

.diff_item2 {
  font-size: 16px;
  font-family: PingFang;
  font-weight: 500;
  color: #666666;
  line-height: 24px;
  font-family: PingFang-SC-Medium;
}

@media (max-width: 990px)  {
  .diffImg {
    width: 100%;
    height: auto;
    border-radius: 18px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .diffImg img{
    width: 100%;
    border-radius: 10px;
  }
  .diff_content {
    flex-direction:column;
  }

  .diff_span {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-right: 0px;
  }

  .diff_items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  .diff_items:last-child{
      margin-bottom: 0;
  }

  .diff_item1 {
    font-size: 22px;
    font-family: PingFang-SC-Bold;
    font-weight: 800;
    color: #333333;
    line-height: 22px;
    margin-bottom: 19px;
  }

  .diff_item2 {
    font-size: 16px;
    font-family: PingFang;
    font-weight: 500;
    color: #666666;
    line-height: 30px;
    font-family: PingFang-SC-Medium;
    text-align: justify;
  }
}
</style>
