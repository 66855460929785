<template>
  <div>
      <div class="coreFunctions">
        <div class="bady_width">
          <p class="big_titles">核心功能</p>
          <div class="core_content">
            <div class="coreImg">
              <img :src="coreImg" />
            </div>
            <div class="core_span">
              <div v-for="(item, index) in spanList" :key="index" class="core_items">
                <span class="span_item1">{{ item.firstSpan }}</span>
                <span class="span_item2">{{ item.secondSpan }}</span>
              </div>
            </div>
          </div>
          </div>
        </div>
  </div>
</template>

<script>
// 公用组件
import "../../assets/css/public.css";
export default {
  name: "coreFunction",
  props: ["coreImg", "spanList"],
  data() {
    return {};
  },

  mounted() {},
};
</script>

<style scoped>


.core_content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.core_span {
  width: 650px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 51px;
}

.core_items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 64px;
}

.core_items:last-child{
    margin-bottom: 0;
}

.span_item1 {
  font-size: 22px;
  font-family: PingFang-SC-Bold;
  font-weight: 800;
  color: #333333;
  line-height: 22px;
  margin-bottom: 16px;
}

.span_item2 {
  font-size: 16px;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
  color: #666666;
  line-height: 24px;
}
/* .coreFunctions_phone{
    display: none;
  } */
.coreImg{
  width: 550px;
  height: 430px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  /* background-color: #eeebf3; */
}
.coreImg img{
  width: 100%;
  border-radius: 10px;
}
@media (max-width: 990px)  {
    .core_content {
      width: 100%;
      flex-direction:column;
      margin: auto;
    }
    .core_span {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0px;
    }
    .core_items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0px;
      margin-top: 30px;
    }
    .core_items:last-child{
        margin-bottom: 0;
    }
    .span_item1 {
      font-size: 22px;
      font-family: PingFang-SC-Bold;
      font-weight: 800;
      color: #333333;
      line-height: 22px;
      margin-bottom: 16px;
    }
    .span_item2 {
      font-size: 16px;
      font-family: PingFang-SC-Medium;
      font-weight: 500;
      color: #666666;
      line-height: 30px;
      text-align: justify;
    }
    .coreImg{
      width: 100%;
      height: auto;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 18px;
    }
    .coreImg img{
      width: 100%;
      border-radius: 10px;
    }
}
</style>
