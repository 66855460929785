<!--
 * @Date: 2024-01-04 16:09:10
 * @LastEditTime: 2024-07-24 14:30:58
 * @Description: file content
-->
<template>
  <div class="header-wrap" :class="{ scrotop_padding: top || lightTheme }">
    <div class="header_wrap" :class="{ scrotop: top || lightTheme }">
      <div :class="['header', !top && !lightTheme && curTheme]">
        <div class="header_div">
          <div class="bigMenu" v-if="serverConsoleTitle">
            <div class="consoleMenu" @click="drawer = true">
              <img
                src="../../assets/img/closess.png"
                class="Menu"
                v-if="drawer"
              />
              <img src="../../assets/img/menu.png" class="closeMenu" v-else />
            </div>
          </div>
          <el-drawer
            title="我是标题"
            :visible.sync="drawer"
            :with-header="false"
            :direction="direction"
            :modal="false"
            :size="drawerWidth"
          >
            <leftNav />
          </el-drawer>
          <!-- logo -->
          <span class="head_title" @click="home()">
            <div class="serverConsoleTitle" v-if="serverConsoleTitle">
              | 服务器控制台
            </div></span
          >

          <div v-for="(item, index) in data" :key="index" class="drpItem">
            <div>
              <!-- 一级菜单（无子菜单） -->
              <div
                v-if="!item.children || !item.children.length"
                :index="index + 2 + ''"
              >
                <div
                  @click="jump(item)"
                  class="itemTitle nav_item jump"
                  :class="{ 'phone-hide': item.name == '文档中心' }"
                >
                  {{ item.name }}
                </div>
              </div>

              <!-- 一级菜单（有子菜单） -->
              <div
                v-else
                @mouseenter="(activeFirstId = item.menuId), (lightTheme = true)"
                @mouseleave="lightTheme = false"
                :index="index + 2 + ''"
              >
                <!-- <div class="itemTitle nav_item" v-if="!item.url"> -->
                <div
                  class="itemTitle nav_item"
                  :class="{ no_underline: item.menuId == 43 }"
                >
                  <span v-if="item.menuId != 43">{{ item.name }}</span>
                  <span v-else class="logo_1024">{{ item.name }} </span>
                  <span class="itemTtile_arrow"></span>

                  <!-- 下拉菜单 -->
                  <div
                    v-if="item.menuId != 43"
                    class="main_menu_wrap"
                    @mouseleave="activeFirstId = -1"
                  >
                    <div
                      class="main_menu"
                      :index="index + 2 + '-1'"
                      v-if="item.children && item.children.length"
                    >
                      <!-- 二级菜单 -->
                      <template v-if="showSecMenu">
                        <div
                          class="sec_menu_wrap"
                          v-for="(str, drp) in item.children"
                          :key="str.menuId"
                          :style="{
                            width: item.children
                              ? 100 / item.children.length + '%'
                              : 'unset',
                          }"
                        >
                          <span class="sec_menu_title">
                            {{ str.name }}
                          </span>

                          <!-- 三级菜单 -->
                          <div
                            v-for="thirdMenuItem in str.children"
                            :key="thirdMenuItem.menuId"
                            :class="{
                              third_menu_wrap: true,
                              no_children: !thirdMenuItem.children,
                            }"
                          >
                            <span
                              v-if="thirdMenuItem.children"
                              class="third_menu_title"
                            >
                              {{ thirdMenuItem.name }}
                            </span>

                            <!-- 三级菜单（无子集） -->
                            <div
                              v-else
                              :class="{
                                third_menu_item: true,
                                disable:
                                  thirdMenuItem.status === 0 ||
                                  thirdMenuItem.tips === 2,
                                'event-none':
                                  !thirdMenuItem.url ||
                                  thirdMenuItem.status !== 1,
                              }"
                            >
                              <template v-if="thirdMenuItem.type === 1">
                                <div
                                  v-if="
                                    thirdMenuItem.status === 0 ||
                                    thirdMenuItem.tips === 2
                                  "
                                ></div>
                                <template v-else>
                                  <img
                                    src="@/assets/img/nav/icon-menu-normal.png"
                                    type="normal"
                                    alt=""
                                  />
                                  <img
                                    src="@/assets//img/nav/icon-menu-hover.png"
                                    type="hover"
                                    alt=""
                                  />
                                </template>
                              </template>

                              <span
                                class="name"
                                @click.stop="jump(thirdMenuItem)"
                              >
                                {{ thirdMenuItem.name }}

                                <ItemTag
                                  v-if="thirdMenuItem.tips"
                                  :text="
                                    thirdMenuItem.tips === 1
                                      ? '即将更新'
                                      : '近期开放'
                                  "
                                  :type="thirdMenuItem.tips"
                                />
                              </span>
                            </div>

                            <!-- 四级菜单 -->
                            <div
                              v-for="fourthMenuItem in thirdMenuItem.children"
                              :key="fourthMenuItem.menuId"
                              :class="{
                                fourth_menu_wrap: true,
                                disable:
                                  fourthMenuItem.status === 0 ||
                                  fourthMenuItem.tips === 2,
                                'event-none':
                                  !fourthMenuItem.url ||
                                  fourthMenuItem.status !== 1,
                              }"
                            >
                              <template v-if="fourthMenuItem.type === 1">
                                <div
                                  v-if="
                                    fourthMenuItem.status === 0 ||
                                    fourthMenuItem.tips === 2
                                  "
                                ></div>
                                <template v-else>
                                  <img
                                    src="@/assets/img/nav/icon-menu-normal.png"
                                    type="normal"
                                    alt=""
                                  />
                                  <img
                                    src="@/assets//img/nav/icon-menu-hover.png"
                                    type="hover"
                                    alt=""
                                  />
                                </template>
                              </template>

                              <span
                                class="name"
                                @click.stop="jump(fourthMenuItem)"
                              >
                                {{ fourthMenuItem.name }}

                                <ItemTag
                                  v-if="fourthMenuItem.tips"
                                  :text="
                                    fourthMenuItem.tips === 1
                                      ? '即将更新'
                                      : '近期开放'
                                  "
                                  :type="fourthMenuItem.tips"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </template>

                      <!-- 二级菜单样式2 -->
                      <template v-else>
                        <!-- 无子菜单 -->
                        <ul class="sec-menu-wrap-other no_children">
                          <li
                            v-for="(str, drp) in item.children.filter(
                              (item) => !item.children,
                            )"
                            :key="str.menuId"
                            :class="{
                              'sec-menu-item-other': true,
                              disable: str.status === 0 || str.tips === 2,
                              'event-none': !str.url || str.status !== 1,
                            }"
                          >
                            <span class="name" @click.stop="jump(str)">
                              {{ str.name }}

                              <ItemTag
                                v-if="str.tips"
                                :text="str.tips === 1 ? '即将更新' : '近期开放'"
                                :type="str.tips"
                              />
                            </span>
                          </li>
                        </ul>

                        <!-- 含子菜单 -->
                        <ul class="sec-menu-wrap-other">
                          <li
                            v-for="(str, drp) in item.children.filter(
                              (item) => item.children,
                            )"
                            :key="str.menuId"
                            class="sec-menu-item-other"
                          >
                            <span class="sec-menu-title">
                              {{ str.name }}
                            </span>

                            <!-- 三级菜单 -->
                            <div
                              v-for="thirdMenuItem in str.children"
                              :key="thirdMenuItem.menuId"
                              :class="{
                                'third-menu-wrap-other': true,
                                disable:
                                  thirdMenuItem.status === 0 ||
                                  thirdMenuItem.tips === 2,
                                'event-none':
                                  !thirdMenuItem.url ||
                                  thirdMenuItem.status !== 1,
                              }"
                            >
                              <template v-if="thirdMenuItem.type === 1">
                                <div
                                  v-if="
                                    thirdMenuItem.status === 0 ||
                                    thirdMenuItem.tips === 2
                                  "
                                ></div>
                                <template v-else>
                                  <img
                                    src="@/assets/img/nav/icon-menu-normal.png"
                                    type="normal"
                                    alt=""
                                  />
                                  <img
                                    src="@/assets//img/nav/icon-menu-hover.png"
                                    type="hover"
                                    alt=""
                                  />
                                </template>
                              </template>

                              <span
                                class="name"
                                @click.stop="jump(thirdMenuItem)"
                              >
                                {{ thirdMenuItem.name }}

                                <ItemTag
                                  v-if="thirdMenuItem.tips"
                                  :text="
                                    thirdMenuItem.tips === 1
                                      ? '即将更新'
                                      : '近期开放'
                                  "
                                  :type="thirdMenuItem.tips"
                                />
                              </span>
                            </div>
                          </li>
                        </ul>
                      </template>
                      <!-- 图文介绍 -->
                      <GraphicIntroduction
                        v-if="graphicIntroduction.img"
                        :img="graphicIntroduction.img"
                      />
                    </div>
                  </div>

                  <div v-else class="main_menu_wrap_1024">
                    <ul>
                      <li
                        v-for="(str, drp) in item.children"
                        :key="drp"
                        :class="{ active1024: active1024(str.url) }"
                        @click="jump(str)"
                      >
                        {{ str.name }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- 旧版，一级菜单（有子菜单） -->
              <!-- <div
                v-else
                @mouseenter="(activeFirstId = item.menuId), (lightTheme = true)"
                @mouseleave="lightTheme = false"
                :index="index + 2 + ''"
              >
                <div
                  class="itemTitle nav_item"
                  :class="{ no_underline: item.menuId == 43 }"
                >
                  <span v-if="item.menuId != 43">{{ item.name }}</span>
                  <span v-else class="logo_1024">{{ item.name }} </span>
                  <span class="itemTtile_arrow"></span>
                  <div
                    v-if="item.menuId != 43"
                    class="main_menu_wrap"
                    @mouseleave="activeFirstId = -1"
                  >
                    <div
                      class="main_menu"
                      :index="index + 2 + '-1'"
                      v-if="item.children && item.children.length"
                    >
                      <div class="sec_menu_wrap" v-if="showSecMenu">
                        <div
                          v-for="(str, drp) in item.children"
                          :key="drp"
                          :class="[
                            'sec_menu_item',
                            activeSecId == str.menuId && 'active',
                          ]"
                          @mouseenter="activeSecId = str.menuId"
                        >
                          {{ str.name }}
                        </div>
                      </div>
                      <div class="third_menu_wrap">
                        <div class="mr10_percent">
                          <thirdMenu
                            v-for="menu in thirdMenu.noChildren"
                            :key="menu.menuId"
                            :data="menu"
                            @hover="handleHover"
                            @blur="handleBlur"
                            @jump="jump"
                          ></thirdMenu>
                        </div>
                        <div>
                          <thirdMenu
                            v-for="menu in thirdMenu.hasChildren"
                            :key="menu.menuId"
                            :data="menu"
                            @hover="handleHover"
                            @blur="handleBlur"
                            @jump="jump"
                          ></thirdMenu>
                        </div>
                      </div>
                      <GraphicIntroduction
                        v-if="graphicIntroduction.img"
                        :img="graphicIntroduction.img"
                      />
                    </div>
                  </div>
                  <div v-else class="main_menu_wrap_1024">
                    <ul>
                      <li
                        v-for="(str, drp) in item.children"
                        :key="drp"
                        :class="{ active1024: active1024(str.url) }"
                        @click="jump(str)"
                      >
                        {{ str.name }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>

        <div class="logMain">
          <div
            :class="isHome ? 'serviceConsole' : 'serviceConsoleZ'"
            @click="serviceConsole"
          >
            我的服务
          </div>
          <!-- 登陆后显示 用户名、头像 -->
          <template v-if="logSatus">
            <div
              v-if="nickName"
              class="loggedInstatus head_user_nickname cursor-default"
            >
              <div class="singe-line">{{ nickName }}，欢迎您</div>
              <div class="head_user_nickname_curve"></div>
            </div>
            <div
              class="headSculpture"
              @click.prevent.stop="handleLoginStatus = !handleLoginStatus"
            >
              <!-- <img src="@/assets/img/nav/default-avatar.png" /> -->
              <img :src="headImg" />
              <!-- TODO 326 隐藏退出 -->
              <div
                v-if="handleLoginStatus && nickName !== '演示账号'"
                @click.stop
                class="logout-bar"
              >
                <div class="user-info">
                  <img :src="headImg" />
                  <!-- <img src="@/assets/img/nav/default-avatar.png" /> -->
                  {{ nickName }}
                </div>
                <div class="btn-logout" @click="exit">
                  <div class="power"></div>
                  退出
                </div>
              </div>
            </div>
          </template>

          <div @click="logOn" v-if="notLogIn" class="logMain">
            <!-- 登录 / 注册 -->
            <div class="serviceConsole cursor-pointer">
              <div class="singe-line">注册 / 登录</div>
            </div>
          </div>
        </div>

        <!-- <div class="messageBoard"  >
          <img
            src="../../assets/img/close.png"
            class="close"
            @click="closeMessage"
          />
          <div class="messageBoardList">
            <p>留言列表：</p>
            <p v-for="(item, index) in dataList" :key="index">
              {{ item.userWords }}
            </p>
          </div>
          <div class="messageContent">
            <el-input
              type="textarea"
              resize="none"
              v-model="form.message"
              placeholder="请输入留言内容"
              class="messageBoardContent"
              maxlength="300"
              show-word-limit
            ></el-input>
            <div class="sendButton" @click="onSubmit">发送</div>
          </div>
        </div> -->
        <!-- 合作反馈 -->
        <!-- <div class="cooperate" @click="messageBoard">
          <img src="../../assets/img/home/feedback.png" />
          <p>合作<br />反馈</p>
        </div> -->

        <!-- 留言板 -->
        <el-popover
          v-if="leaveMessageBtn"
          popper-class="cooperate-feedback-pop"
          placement="top-end"
          trigger="hover"
          :visible-arrow="false"
        >
          <div @mouseover="onShowPopMessage" @mouseleave="onHidePopMessage">
            <div class="item" @click="messageBoard('产品建议')">
              <i class="lamp"></i> 产品建议
            </div>
            <div class="item" @click="messageBoard('合作意向')">
              <i class="libra"></i>合作意向
            </div>
            <div class="item" @click="messageBoard('报告法律问题')">
              <i class="suitcase"></i>报告法律问题
            </div>
          </div>

          <div
            v-if="leaveMessageBtn"
            class="cooperate-feedback"
            slot="reference"
            @click="leaveMessageBtn = false"
          ></div>
        </el-popover>

        <div v-show="leaveMessage" class="message-board">
          <div class="header">
            <p class="name">{{ form.messageName }}</p>

            <i class="el-icon-close" @click="closeMessage"></i>
          </div>

          <el-input
            type="textarea"
            :rows="14"
            placeholder="请输入内容"
            maxlength="300"
            show-word-limit
            resize="none"
            @keydown.native="onSubmit"
            v-model.trim="form.message"
          >
          </el-input>

          <el-button
            class="send-btn"
            type="primary"
            @click="onSubmit"
            :disabled="!form.message"
            >发送</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  // weiXinuser,
  getMessageList,
  messageToDhgate,
  // wechatLogin,
  getMenuTree,
  getTreeList,
  saveRecentUsedApp,
  getUserInfo,
} from '@/api/aidata.js';
import { registerForSellerMeeting } from '@/api/login';
import { mapGetters, mapMutations } from 'vuex';
import leftNav from '../../views/code/leftNav.vue';
import thirdMenu from './components/thirdMenu.vue';
import GraphicIntroduction from './components/introduction.vue';
import '../../assets/css/public.css';
// import { getLogin } from "@/api/login";
import ItemTag from './components/tag.vue';
import defaultAvatar from '../../assets/img/nav/default-avatar.png';

export default {
  components: {
    leftNav,
    thirdMenu,
    GraphicIntroduction,
    ItemTag,
  },

  name: 'viewheader',

  props: {
    // colorful, 未使用先注释
    // serviceSole, 未使用先注释
    isHome: Boolean,
    // 主题色 参数: 'dark'/'light' dark
    theme: {
      type: String,
      default: 'dark',
    },
  },

  computed: {
    curTheme() {
      return `theme_${this.theme}`;
    },
    ...mapGetters(['userInfo']),
  },

  data() {
    return {
      activeSecId: 0,
      activeFirstId: 0,
      active: 0,
      chatBox: false,
      logSatus: false,
      notLogIn: true,
      tokenKey: '',
      openIdKey: '',
      statusKey: '',
      nickName: '',
      headImg: '',
      handleLoginStatus: false, // 展示退登框
      data: [],
      top: '',
      dataList: [],
      form: {
        message: '',
        messageName: '',
      },
      showSecMenu: true,
      leaveMessage: false,
      leaveMessageBtn: true,
      consoNavigation: true,
      consoleText: false,
      serverConsoleTitle: false,
      lightTheme: false,

      // 大菜单
      drawer: false,
      direction: 'ltr',
      drawerWidth: '200px',
      isIntranet:
        Number(this.$route.query.isIntranet) ||
        Number(sessionStorage.getItem('isIntranet')) ||
        false,
      thirdMenu: { hasChildren: [], noChildren: [] },
      // shouldGo:false, //是否拦截（退出登录后返回应拦截，除来自登录页面以外都拦截(不读取路由参数)）
      graphicIntroduction: {},
    };
  },

  watch: {
    logSatus: {
      immediate: true,
      handler(n) {
        this.$store.commit('menu-list/UPDATE_LOG_STATUS', n);
      },
    },
    activeFirstId(val) {
      if (val == -1) return;
      // 不展示二级菜单
      if (val == '003' || val == '002') {
        this.showSecMenu = false;
        this.thirdMenu = this.arrangeThirdData(
          this.data.filter((item) => item.menuId == val)[0].children || [],
        );
      } else {
        this.showSecMenu = true;
        // 获取二级菜单默认选中id
        this.activeSecId =
          this.data.filter((item) => item.menuId === val)[0]?.children[0]
            .menuId || -1;
        this.thirdMenu = this.arrangeThirdData(
          this.data
            .filter((item) => item.menuId === val)[0]
            ?.children.filter((item) => item.menuId == this.activeSecId)[0]
            ?.children || [],
        );
      }
    },
    activeSecId(val) {
      this.thirdMenu = this.arrangeThirdData(
        this.data
          .filter((item) => item.menuId == this.activeFirstId)[0]
          ?.children.filter((item) => item.menuId == val)[0]?.children || [],
      );
    },

    'form.message': {
      handler(val) {
        if (val.length === 300) {
          this.$message({
            message: '留言内容不能超过300字',
            type: 'warning',
          });
        }
      },
    },
  },

  created() {
    // TODO 326免登陆回调逻辑
    // this.registerForSellerMeetingDemo()
    //   .then((resp) => {
    //     if (resp) this.getUserInfo();
    //   })
    //   .catch((err) => {
    //     // 非演示账号，直接免登陆
    //     this.registerForSellerMeetingData().finally(() => {
    //       this.getUserInfo();
    //     });
    //   });
    this.getUserInfo();

    if (this.isIntranet) {
      window.sessionStorage.setItem('isIntranet', 1);
    }

    this.getTreeList();

    this.unionIdKey = this.$route.query.union_id;
    this.openIdKey = this.$route.query.open_id;
    this.statusKey = this.$route.query.status;
    this.$store.commit('user-info/SET_UNION_ID', this.unionIdKey);
    // console.log('this.unionIdKey', this.unionIdKey)
    // console.log('this.statusKey', this.statusKey)

    // 判断是移动的端  ||  pc端
    // if (window.matchMedia('(max-width: 990px)').matches) {
    //   // console.log("这是一个移动设备。");
    //   if (localStorage.getItem('tokenKey')) {
    //     this.notLogIn = false;
    //     this.logSatus = true;
    //     this.nickName = localStorage.getItem('userName');
    //     this.headImg = localStorage.getItem('headImgKey');
    //     this.weiXinuser(localStorage.getItem('tokenKey'));
    //   } else {
    //     this.notLogIn = true;
    //     this.logSatus = false;
    //   }
    // } else {
    //   // console.log("这是平板电脑或台式电脑。");
    //   // 登录 --------------------------------
    //   if (this.unionIdKey) {
    //     if (sessionStorage.getItem('shouldGo') === 'false') {
    //       this.notLogIn = true;
    //       this.logSatus = false;
    //       this.consoleText = false;
    //     } else {
    //       //有token
    //       // 获取 status
    //       if (this.statusKey == 1) {
    //         this.notLogIn = false;
    //         this.logSatus = true;

    //         this.wechatLogin();
    //       } else {
    //         // 跳转登录页
    //         this.$router.push({
    //           query: {
    //             union_id: this.unionIdKey,
    //             open_id: this.openIdKey,
    //             status: this.statusKey,
    //           },
    //           path: '/logIn',
    //         });
    //         this.notLogIn = true;
    //         this.logSatus = false;
    //       }
    //     }
    //     // if(sessionStorage.getItem("shouldGo") && sessionStorage.getItem("shouldGo") !== 'false'){
    //     //   //有token
    //     //     // 获取 status
    //     //     if (this.statusKey == 1) {
    //     //         this.notLogIn = false;
    //     //         this.logSatus = true;

    //     //         this.wechatLogin();
    //     //     } else {
    //     //         // 跳转登录页
    //     //         this.$router.push({
    //     //           query: {
    //     //             union_id: this.unionIdKey,
    //     //             open_id: this.openIdKey,
    //     //             status: this.statusKey,
    //     //           },
    //     //           path: "/logIn",
    //     //         });
    //     //         this.notLogIn = true;
    //     //         this.logSatus = false;
    //     //     }
    //     // }else{
    //     //   this.notLogIn = true;
    //     //   this.logSatus = false;
    //     //   this.consoleText = false
    //     // }
    //   } else {
    //     // 地址栏无token   判断本地是否存储token了
    //     if (localStorage.getItem('userName')) {
    //       this.notLogIn = false;
    //       this.logSatus = true;
    //       this.nickName = localStorage.getItem('userName');
    //       this.headImg = localStorage.getItem('headImgKey');
    //       const isAdministrator = localStorage.getItem('isAdministrator');
    //       // console.log('isAdministrator', isAdministrator)
    //       if (isAdministrator && isAdministrator == 1) {
    //         this.consoleText = true;
    //       }
    //     } else {
    //       this.notLogIn = true;
    //       this.logSatus = false;
    //       this.consoleText = false;
    //     }
    //   }
    // }

    // 根据当前页  区分是控制台
    var servicePathValue = this.$route.path;
    if (
      servicePathValue == '/listCode' ||
      servicePathValue == '/addCode' ||
      servicePathValue == '/resultCode'
    ) {
      this.consoNavigation = false;
      this.consoleText = false;
      this.serverConsoleTitle = true;
    } else {
      this.consoNavigation = true;
      // this.consoleText = true
      this.serverConsoleTitle = false;
    }
    window.addEventListener('scroll', this.handleScroll, true);
    document.addEventListener('click', this.cancelPop);
  },

  methods: {
    ...mapMutations('user-info', ['SET_USER_INFO']),
    /**
     * @description: 获取用户信息
     * @return {*}
     */
    async getUserInfo() {
      // const token = localStorage.getItem('tokenKey') || '';
      const token =
        localStorage.getItem('tokenKey') || this.$cookies.get('tokenKey') || ''; // 326 临时获取 token 逻辑更改

      if (!token) {
        this.notLogIn = true;
        this.logSatus = false;
        return;
      }
      try {
        const {
          data: { data, state },
        } = await getUserInfo({
          token,
        });
        if (state === '0x0000') {
          this.headImg = defaultAvatar;
          this.nickName = data.userName;
          this.notLogIn = false;
          this.logSatus = true;
          this.consoleText = data.isAdministrator == 1 ? true : false;
          localStorage.setItem('isAdministrator', data.isAdministrator);
          localStorage.setItem('userId', data.userId);
          this.SET_USER_INFO(data);
        } else {
          this.notLogIn = true;
          this.logSatus = false;
          this.consoleText = false;
        }
      } catch (e) {
        console.log(e);
        this.notLogIn = true;
        this.logSatus = false;
        this.consoleText = false;
      }
    },

    // 326卖家大会专用注册接口（免登录）
    // registerForSellerMeetingData() {
    //   // 判断是否有 tokenKey
    //   if (this.$cookies.isKey('tokenKey') && this.$cookies.get('tokenKey')) {
    //     // 如果有，返回 tokenKey
    //     return new Promise((resolve, reject) => {
    //       resolve(this.$cookies.get('tokenKey'));
    //     });
    //   } else {
    //     // 如果没有，调用 registerForSellerMeeting 接口，获取 token
    //     return registerForSellerMeeting().then((resp) => {
    //       // 如果有 token ，将 token 存入 cookie
    //       if (resp.data.data.token) {
    //         this.$cookieStore.setCookie('tokenKey', resp.data.data.token, 7);
    //         localStorage.setItem('tokenKey', resp.data.data.token);
    //       }
    //     });
    //   }
    // },

    // TODO 326卖家大会演示链接免登陆
    // registerForSellerMeetingDemo() {
    //   const isDisplayAccount = this.$route.query.isDisplayAccount === 'true';

    //   return new Promise((resolve, reject) => {
    //     // 展示账号直接调用登录
    //     if (isDisplayAccount) {
    //       getLogin({
    //         phone: '15701227125',
    //         password: 'seller_meeting_demo',
    //         loginType: 1,
    //       })
    //         .then((resp) => {
    //           if (resp.data.state === '0x0000' && !!resp.data.data.token) {
    //             this.$cookieStore.setCookie(
    //               'tokenKey',
    //               resp.data.data.token,
    //               7
    //             );
    //             localStorage.setItem('tokenKey', resp.data.data.token);

    //             resolve(resp.data.data.token);
    //           } else {
    //             reject(false);
    //           }
    //         })
    //         .catch((err) => {
    //           reject(false);
    //         });
    //     } else {
    //       reject(false);
    //     }
    //   });
    // },

    /**
     * @description: 导航跳转前记录
     * @param {*} menuId 菜单id
     * @return {*}
     */
    async recordNavigation(menuId) {
      return new Promise(async (resolve) => {
        try {
          const res = await saveRecentUsedApp({ menuId });
          resolve(res);
        } catch (e) {
          e.message && this.$message.error(e.message);
          resolve(e);
        }
      });
    },

    /**
     * @description: 处理三四级菜单排序
     * @param {*} arr 菜单列表
     * @return {*}
     */
    arrangeThirdData(arr) {
      if (!arr.length) return {};
      let hasChildren = [],
        noChildren = [];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].children && arr[i].children.length > 0) {
          hasChildren.push(arr[i]);
        } else {
          noChildren.push(arr[i]);
        }
      }
      return { hasChildren, noChildren };
    },

    /**
     * @description: 判断是否为1024菜单
     * @param {*} url
     * @return {*}
     */
    active1024(url) {
      return url == this.$route.path;
    },

    /**
     * @description: 关闭logoutBar
     * @param {*} events
     * @return {*}
     */
    cancelPop(events) {
      let tp = document.querySelector('.logout-bar');
      if (tp) {
        if (!tp.contains(events.target)) {
          this.handleLoginStatus = false;
        }
      }
    },

    /**
     * @description: 根据顶部距离固定
     * @return {*}
     */
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop < 5) {
        this.top = false;
      } else {
        this.top = true;
      }
    },

    /**
     * @description: 获取导航栏菜单数据
     * @return {*}
     */
    getTreeList() {
      const menuTree = window.sessionStorage.getItem('MenuTree') || '';
      if (menuTree) {
        this.data = JSON.parse(menuTree);
      }
      getMenuTree({
        appCode: '系统菜单',
        visible: 'Y',
        isIntranet: !!this.isIntranet,
      }).then(({ data = {} }) => {
        if (data.state === '0x0000') {
          this.data = data.data;
          // this.$store.commit('menu-list/UPDATE_NAV_LIST', data.data);
          window.sessionStorage.setItem('MenuTree', JSON.stringify(data.data));
        }
      });
    },

    /**
     * @description: 微信登录获取二维码接口  wangdi
     * @return {*}
     */
    logOn() {
      this.$router.push({
        path: '/logIn/register',
      });
    },

    /**
     * @description: 退出登录
     * @param {*} e
     * @return {*}
     */
    exit(e) {
      this.notLogIn = true;
      this.logSatus = false;
      window.localStorage.clear();
      this.$router.push({
        path: '/logIn/register',
      });
    },

    /**
     * @description: 留言板功能
     * @return {*}
     */
    messageBoard(name) {
      // 留言板
      // this.getMessageList();
      // this.$router.push({
      //     path: '/messageBoard'
      // });
      this.leaveMessage = true;

      this.form.messageName = name;
      this.form.message = '';
    },

    /**
     * @description: 留言板 popover 移入样式改变
     * @return {*}
     */
    onShowPopMessage() {
      const messageLeaveBtnEl =
        document.getElementsByClassName('cooperate-feedback');
      messageLeaveBtnEl[0].classList.add('active');
    },

    onHidePopMessage() {
      const messageLeaveBtnEl =
        document.getElementsByClassName('cooperate-feedback');

      messageLeaveBtnEl[0].classList.remove('active');
    },

    /**
     * @description: 获取留言板数据
     * @return {*}
     */
    getMessageList() {
      getMessageList().then((res) => {
        if (res.data.state === '0x0000') {
          this.dataList = res.data.data;
        }
      });
    },

    /**
     * @description: 提交留言
     * @return {*}
     */
    onSubmit(event) {
      if (event.type === 'keydown' && event.code !== 'Enter') {
        return;
      } else if (event.type === 'click') {
        // 处理点击事件的逻辑
      }

      if (!this.form.message) {
        this.$message({
          message: '请填写留言内容',
          type: 'error',
        });
        return;
      }
      messageToDhgate({ userWords: this.form.message }).then(
        ({ data = {} }) => {
          if (data.state === '0x0000') {
            this.$message({
              message: '留言成功',
              type: 'success',
            });
            this.getMessageList();
            this.form.message = '';
            setTimeout(() => {
              this.leaveMessage = false;
            }, 2000);
          } else if (data.state == '0x0008') {
            this.$message.error(data.message);
            this.logOn();
          } else {
            this.$message({
              message: data.message,
              type: 'error',
            });
          }

          if (data.code === '00001') {
            this.$message({
              message: data.message,
              type: 'error',
            });
          }
        },
      );
    },

    /**
     * @description: 关闭留言板
     * @return {*}
     */
    closeMessage() {
      this.leaveMessage = false;
    },

    /**
     * @description: 首页路由
     * @return {*}
     */
    home() {
      if (this.$route.path == '/') {
        this.$router.go(0);
        // window.location.reload();
      } else {
        this.$router.push('/').then(() => {
          // 页面跳转后要执行的操作
          this.$router.go(0);
        });
      }
    },

    /**
     * @description: 路由跳转
     * @param {*} url 跳转url
     * @param {*} menuId 菜单id
     * @return {*}
     */
    async jump({ url, menuId }) {
      // return;
      if (menuId) {
        await this.recordNavigation(menuId);
      }
      if (this.$route.path == url) {
        // this.$router.go(0)
        window.location.reload();
      } else {
        // // 在当前组件的某个方法中进行路由跳转并强制刷新
        if (url.indexOf('#') == -1) {
          this.$router.push({
            path: url,
          });
        } else {
          // 跳转至数字人直播添加 referrer 来源
          if (menuId === '001003001002') {
            // window.open(
            //   url +
            //     `?referrer=${encodeURIComponent(
            //       window.location.href
            //     )}&platUserId=${this.userInfo.relatePlatUser.platUserId}`,
            //   '_self'
            // );
            window.open(url, '_self');
          } else {
            window.location.href = window.location.origin + url;
          }
        }
      }
    },

    /**
     * @description: 跳转服务控制台
     * @return {*}
     */
    serviceConsole() {
      getMenuTree({
        menuPosition: 2,
      }).then(({ data }) => {
        let arrList = [];
        if ((data.state = '0x0000')) {
          data.data.forEach((item) => {
            arrList.push(item);
            if (item.children && item.children.length > 0) {
              arrList = [...arrList, ...item.children];
            }
          });
          // 处理返回的结果 res
          window.sessionStorage.setItem(
            'platformMenuTree',
            JSON.stringify(arrList),
          );
          if (this.nickName) {
            window.location.href = 'https://aishipgo.com/manage/#/my-service';
          } else {
            window.location.href = 'https://aishipgo.com/#/logIn/';
          }
        }
      });
    },

    /**
     * @description: 三四级菜单hover
     * @param {*} item
     * @return {*}
     */
    handleHover(item) {
      const { img_desc_url = '', text = '' } = item;
      this.graphicIntroduction = {
        img: img_desc_url,
        text,
      };
    },

    /**
     * @description: blur事件
     * @return {*}
     */
    handleBlur() {
      this.graphicIntroduction = {
        img: '',
        text: '',
      };
    },
    // 左侧菜单栏
    // consoleMenu(){
    // if(this.drawer){
    //   this.drawer = false
    //   // console.log("123")
    // }else{
    // this.drawer = true
    // console.log("1111111")
    // }

    // }
  },

  beforeDestroy() {
    document.removeEventListener('click', this.cancelPop);
  },
};
</script>

<style lang="less">
@import './view-header.less';
</style>
