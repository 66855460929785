<template>
  <div class="page">
    <!-- 头部 -->
    <Viewheader :isHome="false" ref="navheader" />
    <!-- banner图 -->
    <BannerHead
      :bannerImg="bannerImg"
      :bigTitle_1="bigTitle_1"
      :smallTitle_1="smallTitle_1"
    ></BannerHead>

    <div>
      <!-- 核心功能 -->
      <CoreFunction :spanList="spanList" :coreImg="coreImg"></CoreFunction>

      <!-- 能力展示 -->
      <div class="imfing_main">
        <p class="big_titles">能力展示</p>
        <div class="infing_class">
          <div class="infing_clas1">
            <!-- 请输入商品文本 -->
            <div class="infing_clas2">
              <p>请输入商品文本:<a @click="randomClick">随机示例</a></p>
              <el-form
                :model="formUrl"
                class="formClasses"
                :class="{ frosted_glass: isActive }"
                id="formClasses"
              >
                <el-form-item class="formClasses_1">
                  <el-input
                    type="textarea"
                    resize="none"
                    v-model="formUrl.discription"
                    placeholder="商品标题、短描、⻓描文本..."
                    class="ifrInput"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item class="formClasses_2">
                  <el-button
                    type="primary"
                    @click="dealWithForm('formUrl')"
                    class="chuliBtn"
                    >开始处理</el-button
                  >
                </el-form-item>
              </el-form>

              <div class="msg-tip" style="display: none" id="msgTip">
                文本解析中...
              </div>
              <div class="msg-tip" style="display: none" id="msgTips">
                很抱歉，文本中可能存在敏感内容,请<span class="retry"
                  >重新输入</span
                >。
              </div>
            </div>

            <!-- 处理结果 -->
            <div class="infing_clas2">
              <p>处理结果：</p>
              <div class="infriResult">
                <span v-if="dataOffending">{{ dataOffending }}</span>
                <span v-else>暂未检测侵权词</span>
              </div>
            </div>
          </div>
          <p class="infriP">
            1、模型⽬前仅⽀持英⽂标题，其他语种需进⾏翻译后处理；<br />
            2、模型结果对google系列产品（shopping、⾕歌seo等），进⾏了针对性优化，在此场景下使⽤效果更优。
          </p>
        </div>
      </div>

      <!-- 差异优势 -->
      <!-- <Different :diffImg="diffImg" :diffList="diffList"></Different> -->

      <!-- 应用场景 -->
      <Application :appImg="appImg" :appliList="appliList"></Application>
    </div>

    <!-- 尾部 -->
    <Footering />
  </div>
</template>

<script>
// banner
import BannerHead from '@/components/centerPage/banner_head.vue'
import CoreFunction from '@/components/centerPage/core_function.vue'
import Different from '@/components/centerPage/differential_adv.vue'
import Application from '@/components/centerPage/application.vue'

import top13 from '@/assets/img/gongneng/qinquan/toubu.jpg'
import he13 from '@/assets/img/gongneng/qinquan/hexin.png'
import cha13 from '@/assets/img/gongneng/qinquan/changjing.png'
import yingyong from '@/assets/img/gongneng/qinquan/changjing.png'

// 公用组件
import '../../assets/css/public.css'

import { getOffending } from '@/api/aidata.js'

// 头部
import Viewheader from '../../components/viewheader/viewheader.vue'
// foote组件
import Footering from '../../components/foot/viewfooter.vue'

export default {
  components: {
    BannerHead,
    CoreFunction,
    Different,
    Application,
    Viewheader,
    Footering,
  },
  data() {
    return {
      bannerImg: top13,
      coreImg: he13,
      bigTitle_1: '文本侵权检测',
      smallTitle_1: '对商品标题、长短描等文案中 可能的侵权词进行检测',

      // 核心功能
      spanList: [
        {
          firstSpan: '文本侵权检测',
          secondSpan:
            '对电商商品标题、长短描、评论等文本进行语义相似、变形词等侵权检测。',
        },
        {
          firstSpan: '侵权风险评估',
          secondSpan: '支持接口批量化处理，输出风险点及风险等级。',
        },
      ],

      //   差异优势
      diffImg: cha13,
      diffList: [
        {
          diffFirst: '暂无文案',
          diffSecond:
            '暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案',
        },
      ],

      //   应用场景
      appImg: yingyong,
      appliList: [
        {
          appliFirst: '电商平台/店铺安全',
          appliSecond:
            '1、铺货批量上架电商平台、站外广告投放前，规避可能的侵权风险。',
        },
        {
          appliFirst: '',
          appliSecond: '2、电商平台方、独立站，处理平台商品合规问题。',
        },
      ],
      formUrl: {
        discription:
          'HOT CLASSIC! women fashion England middle long trench coat/high quality brand design double breasted trench coat/cotton fabric size S-XXL 5 colors',
      },
      dataOffending: '   ',
      isActive: false,
      randomList: [
        {
          linkUrl:
            'HOT CLASSIC! women fashion England middle long trench coat/high quality brand design double breasted trench coat/cotton fabric size S-XXL 5 colors',
          keyWord: '',
        },
        {
          linkUrl:
            'lady Cat Eye Frame Sunglasses 40014 Acetate Fiber Frame Gold Metal Legs Luxury Brand Womens Personalized Multifunctional Solar Occhiali da Sole',
          keyWord: '',
        },
        {
          linkUrl:
            'Disposable Black nitrile gloves powder free for Inspection Industrial Lab Home and Supermaket Comfortable Pink',
          keyWord: '',
        },
        {
          linkUrl:
            'celebrity catwalk models straw bag latest design simple and practical Designers Womens Handbags Purses 2021 is specially designed for young girls Classic fashion',
          keyWord: '',
        },
        {
          linkUrl:
            "Medusa Designer T Shirts classic style Top Summer Spring Brand Mens Clothing Men's Fabric Letters Polo T-shirts Lapel Collar Casual Women's Paris T Shirt Tees",
          keyWord: '',
        },
      ],
    }
  },
  computed: {},
  mounted() {},
  methods: {
    // 开始处理
    dealWithForm() {
      if (this.formUrl.discription === '') {
        // return this.$message.error("请输入内容");
        this.$message({
          message: '请输入内容',
          type: 'error',
        })
        return
      }
      this.isActive = true
      document.getElementById('msgTip').style.display = 'block'
      getOffending({
        discription: this.formUrl.discription,
      }).then(({ data, ...rest }) => {
        if (data.state === '0x0000') {
          setTimeout(() => {
            this.isActive = false
            this.dataOffending = data.data
            document.getElementById('msgTip').style.display = 'none'
          }, 1000)
        } else if (data.state == '0x0008') {
          // this.$message.error(data.message);
          this.$message({
            message: data.message,
            type: 'error',
          })
          this.$refs.navheader.logOn()
        } else {
          this.isActive = false
          document.getElementById('msgTip').style.display = 'none'
          this.$message({
            message: data.message,
            type: 'error',
          })
          // this.$message.error(data.message);
        }
      })
    },

    // 随机示例
    randomClick() {
      var index = Math.floor(Math.random() * 5)
      this.formUrl.discription = this.randomList[index].linkUrl
      // this.dataOffending = this.randomList[index].keyWord;
    },
  },
}
</script>

<style>
a {
  cursor: pointer;
}

.infing_class {
  width: 1040px;
  height: 410px;
  background: #f9f8fc;
  border-radius: 6px 6px 6px 6px;
  margin: 0 auto;
  padding: 20px;
}

.ifrInput .el-textarea__inner {
  width: 512px;
  height: 320px;
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  border: none;
}

.infing_clas2 p {
  margin: 0;
  font-size: 16px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #333333;
  line-height: 16px;
  margin-bottom: 19px;
}

.infing_clas2 p a {
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #0042ff;
  line-height: 16px;
  text-decoration: underline;
  margin-left: 19px;
}

.infing_clas1 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.infriP {
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin: 20px 0 0;
}

.formClasses_1 {
  margin-bottom: 15px !important;
}

.formClasses_2 {
  margin-bottom: 0 !important;
}

.chuliBtn {
  margin-left: 400px;
  position: absolute;
  bottom: 22px;
}

.infriResult {
  width: 472px;
  height: 282px;
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  padding: 20px;
}

.infriResult span {
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
  word-break: break-all;
}

.el-button--primary {
  background: #6567e1 linear-gradient(180deg, #361ecd 0%, #777aed 0%);
  border: none;
}

.el-button--primary:hover {
  background: #6567e1 linear-gradient(180deg, #361ecd 0%, #777aed 0%);
}

.frosted_glass {
  pointer-events: none;
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius="3");
}

.msg-tip {
  position: absolute;
  /* left: 37%;
  top: 50%; */
  -webkit-transform: translate(120%, -500%);
  -moz-transform: translate(120%, -500%);
  -ms-transform: translate(120%, -500%);
  transform: translate(120%, -500%);
  padding: 0 32px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 36px;
  line-height: 36px;
  color: #666;
  background: #fff;
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.ifrInput .el-input__count {
  right: 130px;
}
@media screen and (max-width: 990px) {
  .imfing_main {
    width: 93%;
    margin: auto;
  }
  .infing_class {
    width: auto;
    height: auto;
    padding: 10px;
  }
  .infing_clas1 {
    flex-direction: column;
    width: auto;
  }
  .titleClass1 {
    width: auto;
    margin-right: 20px;
  }
  .infing_clas2 {
    width: 100%;
  }
  .txt_class_sev {
    width: 100%;
  }
  .el-textarea__inner {
    width: 100% !important;
  }
  .infriResult {
    width: auto;
  }
  .el-form-item__content {
    text-align: center;
  }
  .chuliBtn {
    position: absolute;
    right: 10px;
    bottom: 20px;
  }
  .infriP {
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #999999;
    line-height: 30px;
    margin: 20px 0 0;
  }
  .msg-tip {
    position: absolute;
    /* left: 37%;
    top: 50%; */
    -webkit-transform: translate(100%, -500%);
    -moz-transform: translate(100%, -500%);
    -ms-transform: translate(100%, -500%);
    transform: translate(100%, -500%);
    padding: 0 32px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 36px;
    line-height: 36px;
    color: #666;
    background: #fff;
    -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
}
</style>
