<template>
  <div>
    <div class="applicationScenario">
      <div class="bady_width">
        <p class="big_titles">应用场景</p>
        <div class="appli_content">
          <div class="appImg">
            <img :src="appImg" />
          </div>

          <div class="appli_span">
            <div
              v-for="(item, index) in appliList"
              :key="index"
              class="appli_items"
            >
              <span class="span_item1">{{ item.appliFirst }}</span>
              <span class="span_item2">{{ item.appliSecond }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 公用组件
import "../../assets/css/public.css";
export default {
  name: "application",
  props: ["appImg", "appliList"],
  data() {
    return {};
  },

  mounted() {},
};
</script>

<style scoped>
.appImg {
  width: 550px;
  height: 430px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.appImg img {
  width: 100%;
  border-radius: 10px;
}
.appli_content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}

.appli_span {
  width: 650px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 63px;
}

.appli_items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
}

.span_item1 {
  font-size: 22px;
  font-family: PingFang-SC-bold;
  font-weight: 800;
  color: #333333;
  line-height: 22px;
}

.span_item2 {
  font-size: 16px;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
  color: #666666;
  line-height: 24px;
  margin-top: 30px;
}

@media (max-width: 990px) {
  .appImg {
    width: 100%;
    height: auto;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .appImg img {
    width: 100%;
    border-radius: 10px;
  }
  .appli_content {
    flex-direction: column;
    margin-bottom: 100px;
  }

  .appli_span {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0px;
  }

  .appli_items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
  }

  .appli_items:last-child {
  }

  .span_item1 {
    font-size: 22px;
    font-family: PingFang-SC-bold;
    font-weight: 800;
    color: #333333;
    line-height: 22px;
  }

  .span_item2 {
    font-size: 16px;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: #666666;
    line-height: 30px;
    text-align: justify;
    margin-top: 16px;
  }
}
</style>
