<!--
 * @Date: 2024-01-08 17:04:35
 * @LastEditTime: 2024-01-08 18:22:37
 * @Description: 图文介绍
-->
<template>
  <div class="graphic-introduction">
    <img :src="img" alt="" />
    <p v-if="text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'GraphicIntroduction',
  props: {
    img: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="less" scoped>
.graphic-introduction {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 28.59%;
  margin-left: auto;
  padding-top: 51px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #4e5969;
  word-break: break-all;
  img {
    width: 100%;
    // margin-bottom: 12px;
    border-radius: 8px;
  }
}
</style>
